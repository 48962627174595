import { cnpj, cpf } from 'cpf-cnpj-validator'
import * as yup from 'yup'

import { tipoContaBanco, tiposChavePix } from '@/utils/types'
import type { Translate } from '@/utils/types/common'

import { env } from '../envs'

export type Validator = (p?: {
  req?: true
  nullable?: true
  subject?: string
  template?: string
  metragemInstalacao?: boolean
  validImovel?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => any

export const createValidation = <A>(
  cb: (
    args: Parameters<Validator>[0] & { t: Translate } & A
  ) => ReturnType<Validator>
) => {
  return cb
}

export const validateFileRecord = yup.mixed().test({
  test: (v: any) => !v || typeof v.url === 'string',
  message: 'Arquivo inválido',
})

export const validateContaBancaria = createValidation(({ t }) =>
  yup.object().shape({
    tipoConta: yup.object().shape({
      value: yup
        .string()
        .equals([...tipoContaBanco], t('validation.type-account-bank-invalid'))
        .required(t('validation.type-account-bank-required')),
    }),
    banco: yup.mixed().test({
      test: (v: any) => v?.value !== undefined,
      message: t('validation.bank-required'),
    }),
    agencia: yup.string().required(t('validation.agency-bank-required')),
    conta: yup.string().required(t('validation.account-bank-required')),
    digito: yup
      .string()
      .nullable()
      .matches(/^\d$/, t('validation.digit-invalid')),
  })
)

export const validateContaPix = createValidation(({ t }) =>
  yup.object().shape({
    chavePix: yup
      .string()
      .when('tipoChavePix.value', {
        is: 'EMAIL',
        then: () =>
          yup
            .string()
            .email(t('validation.email-invalid'))
            .typeError(t('validation.email-invalid')),
      })
      .when('tipoChavePix.value', {
        is: 'CNPJ',
        then: () =>
          yup.string().test({
            test: (v) => !v || cnpj.isValid(v),
            message: t('validation.cnpj-invalid'),
          }),
      })
      .when('tipoChavePix.value', {
        is: 'CPF',
        then: () =>
          yup.string().test({
            test: (v) => !v || cpf.isValid(v),
            message: t('validation.cpf-invalid'),
          }),
      })
      .when('tipoChavePix.value', {
        is: 'CELULAR',
        then: () =>
          yup
            .string()
            .matches(/^\(\d{2}\) 9/, t('validation.cellphone-must-start'))
            .matches(
              /^\(\d{2}\) \d{5}-\d{4}$/,
              t('validation.cellphone-invalid')
            )
            .required(t('validation.cellphone-required')),
      })
      .required(t('validation.pix-key-required')),
    tipoChavePix: yup
      .mixed()
      .test({
        test: (v: any) => !v || tiposChavePix.includes(v.value),
        message: t('validation.type-pix-key-invalid'),
      })
      .required(t('validation.type-pix-key-required')),
  })
)

export const validateDynamicObject = <T extends yup.Maybe<yup.AnyObject>>(
  schema: yup.ObjectSchema<T>
) => {
  return yup.lazy((obj = {}) => {
    const keys = Object.keys(obj)

    const every: any = {}

    for (const key of keys) {
      every[key] = schema
    }

    return yup.object().shape(every)
  })
}

export const validateTelefone = createValidation(({ req, nullable, t }) => {
  let v: yup.StringSchema<string | undefined | null> = yup.string()

  if (process.env.NEXT_PUBLIC_LANGUAGE === 'en') {
    v = yup
      .string()
      .matches(
        /^(1\s?)?(\d{3}|\(\d{3}\))[\s]?\d{3}[\s-]?\d{4}$/,
        t('validation.proposal-invalid-cel')
      )
      .typeError(t('validation.proposal-invalid-cel'))
  } else {
    v = yup
      .string()
      .matches(
        /^\(\d{2}\) \d{4}-\d{4}|\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/,
        t('validation.proposal-invalid-cel')
      )
      .typeError(t('validation.proposal-invalid-cel'))
  }

  if (req) v = v.required(t('validation.proposal-mandatory-cel'))
  if (nullable) v = v.nullable()

  return v
})

export const validateCelular = yup
  .string()
  .matches(/^\(\d{2}\) 9/, 'Celular deve começar com 9 após o DDD')
  .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'Celular inválido')
  .typeError('Celular inválido')

export const validateTelCel = createValidation(({ req, subject = '', t }) => {
  return yup.lazy((v?: string) => {
    if (!v) {
      if (req) {
        return yup
          .string()
          .typeError(t('validation.proposal-invalid-phone'))
          .required(t('validation.proposal-mandatory-phone'))
      }

      return yup.string().typeError(t('validation.proposal-invalid-phone'))
    }

    if (v.length > 14 && process.env.NEXT_PUBLIC_LANGUAGE === 'pt-br') {
      if (req) {
        return validateCelular.required(`O celular ${subject} é obrigatório`)
      }

      return validateCelular
    } else {
      return validateTelefone({ req, subject, t })
    }
  })
})

export const validateCPF = createValidation(
  ({ req, nullable, subject = '', t }) => {
    if (env.LANGUAGE === 'en') {
      const value = yup.string().required(t('validation.cpf-required'))
      return value
    }
    let v: yup.StringSchema<string | undefined | null> = yup
      .string()
      .test({
        test: (v) => !v || cpf.isValid(v),
        message: t('validation.cpf-invalid', { subject }),
      })
      .typeError(t('validation.cpf-invalid', { subject }))

    if (req) v = v.required(t('validation.cpf-required'))
    if (nullable) v = v.nullable()

    return v
  }
)

export const validateDocument = createValidation(
  ({ req, nullable, subject = '', t }) => {
    let v: yup.StringSchema<string | undefined | null> = yup
      .string()
      .typeError(t('validation.proposal-document-rg-invalid', { subject }))
      .min(6, t('validation.proposal-document-rg-6-numbers', { subject }))

    if (req) v = v.required(t('validation.cpf-required'))
    if (nullable) v = v.nullable()

    return v
  }
)

export const validateSsnOrEin = createValidation(
  ({ req, nullable, subject = '', t }) => {
    const ssnRgxStr = '[0-9]{3}-?[0-9]{2}-?[0-9]{4}'
    const einRgxStr = '[0-9]{2}-?[0-9]{7}'

    let v: yup.StringSchema<string | undefined | null> = yup
      .string()
      .typeError(t('validation.ssn-or-ein-type-error', { subject }))
      .matches(
        new RegExp(`^${ssnRgxStr}|${einRgxStr}$`),
        t('validation.ssn-or-ein-invalid', { subject })
      )

    if (req) v = v.required(t('validation.ssn-or-ein-required', { subject }))
    if (nullable) v = v.nullable()

    return v
  }
)

export const validateCNPJ = yup
  .string()
  .test({
    test: (v) => !v || cnpj.isValid(v),
    message: 'O CNPJ é inválido',
  })
  .typeError('O CNPJ é inválido')

export const validateFEIN = yup
  .string()
  .matches(/^[0-9]{2}-[0-9]{7}$/, 'Invalid EIN')
  .typeError('EIN is invalid')

export const validateCpfCnpj = createValidation(({ t }) => {
  return yup.lazy((v?: string) => {
    if (!v) return yup.string().required('Campo obrigatório')

    if (v.length < 15) {
      return validateCPF({ req: true, t })
    } else {
      return validateCNPJ.required('Campo obrigatório')
    }
  })
})

export const validateEmail = createValidation(({ req, nullable, t }) => {
  let v: yup.StringSchema<string | undefined | null> = yup
    .string()
    .typeError(t('validation.proposal-email-required'))
    .email(t('validation.proposal-email-required'))

  if (req) {
    v = v.required(t('validation.email-required'))
  }

  if (nullable) v = v.nullable()

  return v
})

export const validateNomeCompleto = createValidation(
  ({ req, subject = '', t }) => {
    let v = yup.string().typeError(t('validation.name-invalid', { subject }))

    if (req) {
      v = v.required(t('validation.name-required', { subject }))
    }

    return v
  }
)

const INVALID_RG = [
  '000000000',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
]

export const rgIsValid = (rg: string) => {
  const numeric = String(rg).replace(/[^\d]+/g, '')

  if (!numeric || numeric.length !== 9 || INVALID_RG.includes(numeric)) {
    return false
  }

  let sum = parseInt(numeric[8], 10) * 100

  for (let i = 0; i < 8; i += 1) {
    sum += parseInt(numeric[i], 10) * (2 + i)
  }

  return sum % 11 === 0
}

export const validateRG = createValidation(
  ({ req, nullable, subject = '', t }) => {
    let v: yup.StringSchema<string | undefined | null> = yup
      .string()
      .typeError(t('validation.proposal-document-rg-invalid', { subject }))
      .min(6, t('validation.proposal-document-rg-6-numbers', { subject }))

    if (req)
      v = v.required(t('validation.proposal-document-rg-required', { subject }))
    if (nullable) v = v.nullable()

    return v
  }
)

export const validSsnAndEin = (document: string) => {
  // Remover caracteres não numéricos
  document = document.replace(/\D/g, '')

  // Verificar se o SSN ou EIN possui 9 dígitos
  if (document.length !== 9) {
    return false
  }

  // Verificar se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(document)) {
    return false
  }

  return true
}

export const validarEmail = (email: string) => {
  // Expressão regular para validar o formato do e-mail
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  // Verificar se o e-mail corresponde ao formato esperado
  return regex.test(email)
}
