import * as React from 'react'

import { c } from '../../utils/etc'

export interface SideImageLayoutProps {
  className?: string
  leftContainerClassName?: string
  rightContainerClassName?: string
  renderImage?: React.ReactElement
  useWidth50?: string
}

const SideImageLayout: React.FCC<SideImageLayoutProps> = ({
  renderImage,
  className,
  leftContainerClassName,
  rightContainerClassName,
  children,
  useWidth50,
}) => {
  return (
    <section className={c('flex min-h-screen items-stretch ', className)}>
      <div
        className={c(
          `w-full ${
            useWidth50 ? useWidth50 : 'md:min-w-[480px]'
          } md:max-w-screen-md md:flex-1`,
          leftContainerClassName
        )}
      >
        {children}
      </div>
      <span className="hidden flex-1 md:block">
        <div className={c('relative h-full', rightContainerClassName)}>
          {renderImage}
        </div>
      </span>
    </section>
  )
}

export default SideImageLayout
