import Image from 'next/legacy/image'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import * as React from 'react'

import * as Icon from '@/components/core/internicons'
import SideImageLayout from '@/layouts/side-image'
import LogoImage from '@/public/logo_eos_us_colored.png'
import { env } from '@/utils/envs'

interface AuthLayoutProps {
  children: React.ReactNode
  useWidth50?: string
}

const AuthLayout = ({ useWidth50, children }: AuthLayoutProps) => {
  const t = useTranslations('login')
  return (
    <SideImageLayout
      rightContainerClassName="flex flex-col justify-center items-center px-6"
      useWidth50={useWidth50}
      renderImage={
        <>
          <div className="relative z-50 block max-w-screen-sm ">
            <h1 className="text-center text-title-lg font-semibold text-white">
              {t('auth-title')}
            </h1>
            <hr className="mx-auto my-2 w-8 border-2 border-warning-300" />
            <h2 className="text-center text-title-lg text-white">
              {t('auth-description')}
            </h2>
          </div>
          <span className="to-transparent absolute inset-0 z-40 bg-gradient-to-b from-[#00000030] pointer-events-none" />
          <Image
            src="/img/bg-login.jpg"
            alt=""
            layout="fill"
            objectFit="cover"
          />
        </>
      }
    >
      <div className="flex min-h-full flex-col">
        <Link href={env.NEXT_PUBLIC_WEBSITE} className="max-w-max p-6">
          {process.env.NEXT_PUBLIC_LANGUAGE == 'pt-br' ? (
            <>
              <Icon.Logo />
            </>
          ) : (
            <div className="max-w-[140px]">
              <Image alt="Logo Eos Solar Loan" src={LogoImage} />
            </div>
          )}
        </Link>
        <div className="flex flex-1 flex-col items-center justify-center px-6">
          {children}
        </div>
      </div>
      {/* {children} */}
    </SideImageLayout>
  )
}

export default AuthLayout
