import { useTranslations } from 'next-intl'
import * as React from 'react'
import * as yup from 'yup'
import YupPassword from 'yup-password'

import { useAuth } from '@/hooks/use-auth'
import { validateFormValues } from '@/utils/etc'
import type { Translate } from '@/utils/types/common'
import { validateCNPJ, validateCPF } from '@/utils/validators/etc'

YupPassword(yup)

export interface LoginFormValues {
  credencial: string
  senha: string
  manterConectado: boolean
}

const validateWithT = (t: Translate) => {
  const PASS_MSG = t('must-contain')

  return validateFormValues(
    yup.object().shape({
      credencial: yup.lazy((v?: string) => {
        if (!v) return yup.string().required(t('required-field'))

        const n = +v.replace(/[.\-/]/g, '')

        if (n) {
          if (String(n).length < 12) {
            return validateCPF({ req: true, t })
          }

          if (String(n).length > 11 && String(n).length < 15) {
            return validateCNPJ.required(t('cnpj-required'))
          }
        }

        return yup
          .string()
          .email(t('email-invalid'))
          .required(t('email-required'))
      }),

      senha: yup
        .string()
        .typeError(t('password-invalid'))
        .min(8, t('limit-characters'))
        .minNumbers(1, PASS_MSG)
        .minLowercase(1, PASS_MSG)
        .minUppercase(1, PASS_MSG)
        .minSymbols(1, PASS_MSG)
        .required(t('password-required')),

      manterConectado: yup.bool().optional().default(false),
    })
  )
}

export const useLoginForm = () => {
  const { context } = useAuth()
  const t = useTranslations('validation')

  const validate = React.useMemo(() => validateWithT(t), [t])

  return {
    context,
    validate,
  }
}
