import type { GetServerSideProps } from 'next'
import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'

import LoginForm from '@/components/auth/LoginForm'
import AuthLayout from '@/layouts/auth-layout'
import { env } from '@/utils/envs'
import type { Page } from '@/utils/types/etc'

const LoginPage: Page = () => {
  const t = useTranslations('login')
  return (
    <>
      <NextSeo title={t('meta-tag-title')} />
      <LoginForm />
    </>
  )
}

LoginPage.getLayout = (page) => {
  return <AuthLayout>{page}</AuthLayout>
}

export const getServerSideProps: GetServerSideProps = async () => {
  try {
    const resposta = await fetch(
      `${env.NEXT_PUBLIC_PORTAL_BACKEND}/auth/maintenance`
    )

    if (!resposta.ok) {
      throw new Error('Request failed')
    }

    const checkMaintenance = await resposta.json()
    if (checkMaintenance.data && checkMaintenance.data.id) {
      return {
        redirect: {
          destination: '/maintenance',
          permanent: false,
        },
      }
    }

    return { props: {} }
  } catch (error) {
    console.error('Erro na requisição:', error)
    return {
      props: {},
    }
  }
}

export default LoginPage
