import Link from 'next/link'
import { useTranslations } from 'next-intl'
import React from 'react'

import AuthFormBase from '@/components/auth/AuthFormBase'
import * as Input from '@/components/core/input'
import { useAuth } from '@/hooks/use-auth'

import { useLoginForm } from './LoginForm.hook'

const LoginForm: React.FC = () => {
  const { validate } = useLoginForm()
  const { login } = useAuth()
  const t = useTranslations('login')

  return (
    <AuthFormBase
      title={t('title')}
      subtitle={t('desciption')}
      submitText={t('button-enter')}
      underButtonText={t('login-account-underButtonText')}
      secondaryActionLink={`/cadastro`}
      secondaryActionText={t('login-account-secondaryActionText')}
      validate={validate}
      onSubmit={(values) => login(values)}
    >
      <Input.Form.Field
        type="credencial"
        name="credencial"
        label={t('login-input')}
      />
      <Input.Form.Password name="senha" label={t('password-input')} />

      <span className="flex items-center justify-end">
        <Link href="/login/esqueci-senha" className="hover:underline">
          {t('forgot-password')}
        </Link>
      </span>
    </AuthFormBase>
  )
}

export default LoginForm
