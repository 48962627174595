import type { FormApi, ValidationErrors } from 'final-form'
import Link from 'next/link'
import React from 'react'
import { Form } from 'react-final-form'

import Button from '@/components/core/Button'
import { c } from '@/utils/etc'

export interface AuthFormBaseProps<T> {
  title: string
  subtitle: string
  secondaryActionText?: string
  secondaryActionLink?: string
  underButtonText?: string
  submitText: string
  className?: string
  socialButtons?: boolean
  fixedInitialValues?: Partial<T>
  onSubmit: (values: T, actions: FormApi<T, Partial<T>>) => void
  validate?: (values: T) => ValidationErrors | Promise<ValidationErrors>
}

const AuthFormBase = <T,>({
  title,
  subtitle,
  underButtonText,
  secondaryActionLink,
  secondaryActionText,
  submitText,
  children,
  className,
  // socialButtons,
  fixedInitialValues,
  onSubmit,
  validate,
}: React.PropsWithChildren<AuthFormBaseProps<T>>) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={fixedInitialValues}
      subscription={{ submitting: true }}
      validate={validate}
      render={({ submitting, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-[411px] space-y-6"
        >
          <section className="space-y-2 text-center">
            <h1 className="text-title-xs font-bold text-primary-500">
              {title}
            </h1>
            <h3 className="whitespace-pre-line text-body-md font-medium text-light-gray-500">
              {subtitle}
            </h3>
          </section>

          <section className={c('space-y-4', className)}>{children}</section>

          <section className="space-y-4">
            <Button
              loading={submitting}
              type="submit"
              className="button--primary w-full"
            >
              {submitText}
            </Button>

            {/* {socialButtons && (
              <>
                <Link href={`${portalEndpoint}/sso/facebook`}>
                  <a className="button button--outline block">
                    <span className="flex items-center justify-center space-x-2">
                      <div className="relative h-6 w-6">
                        <Image
                          src="/facebook-logo.svg"
                          layout="fill"
                          alt=""
                          quality={100}
                          objectFit="cover"
                        />
                      </div>
                      <p className="font-semibold text-primary-300">
                        Continuar com o Facebook
                      </p>
                    </span>
                  </a>
                </Link>

                <Link
                  href={`${portalEndpoint}/sso/google?invite_token=${localStorage.getItem(
                    INVITE_TOKEN_LOCALSTORAGE_KEY
                  )}`}
                >
                  <a className="button button--outline block">
                    <span className="flex items-center justify-center space-x-2">
                      <div className="relative h-6 w-6">
                        <Image
                          src="/google-logo.svg"
                          layout="fill"
                          alt=""
                          quality={100}
                          objectFit="cover"
                        />
                      </div>
                      <p className="font-semibold text-primary-300">
                        Continuar com o Google
                      </p>
                    </span>
                  </a>
                </Link>
              </>
            )} */}

            {underButtonText && (
              <p className="text-center font-medium text-light-gray-500">
                {underButtonText}{' '}
                {secondaryActionLink && secondaryActionText && (
                  <Link href={secondaryActionLink} className="hover:underline">
                    {secondaryActionText}
                  </Link>
                )}
              </p>
            )}
          </section>
        </form>
      )}
    />
  )
}

export default AuthFormBase
